import React, { useState } from "react";
import {
  Button,
  Card,
  Grid,
  Link,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import userManagementIcon from "../../assets/icons/user-management.svg";
import fleetManagementIcon from "../../assets/icons/fleet-management.svg";
import bookingManagementIcon from "../../assets/icons/booking-management.svg";
import airportManagementIcon from "../../assets/icons/airport-management.svg";
import couponsManagementIcon from "../../assets/icons/coupons-management.svg";
import emptyLegManagementIcon from "../../assets/icons/empty-leg-management.svg";
import flightsManagementIcon from "../../assets/icons/flights-management.svg";
import manageBMJRewardIcon from "../../assets/icons/manage-bmj-reward-points.svg";
import offeredFlightManagementIcon from "../../assets/icons/offered-flight-management.svg";
import quotationManagementIcon from "../../assets/icons/quotation-management.svg";
import bookingStatsIcon from "../../assets/icons/bookig-stats.svg";
import operatorManagementIcon from "../../assets/icons/operator-management.svg";
import queryManagementIcon from "../../assets/icons/query-management.svg";
import feedManagementIcon from "../../assets/icons/feed-management-icon.svg";
import blogsManagementIcon from "../../assets/icons/blogs-management-icon.svg";
import messengerIcon from "../../assets/icons/messenger-icon.svg";
import { sessionUtility } from "../../utility/sessionUtility";

const useStyles = makeStyles({
  profileContainer: {
    backgroundColor: "#f7f8fc",
    // padding: "3em"
  },
  coverImage: {
    // backgroundImage: 'url("https://images.unsplash.com/photo-1687220296797-9901d7006479?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyN3x8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "200px",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
  },
  cameraIcon: {
    fontSize: "1.5rem",
  },
  // cameraIconBtn: {
  //   position: "absolute",
  //   bottom: "12%",
  //   right: "8%",
  //   background: "#fff",
  //   boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
  // },
  cameraIconBtn: {
    position: "absolute",
    top: "50%",
    transform: "translateY(50%)",
    // left: '15%',
    left: "23%",
    zIndex: 200,
    background: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
  },
  profileImage: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 100,
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    outline: "3px solid #9EBCF8",
  },
  heading: {
    fontSize: "24px",
    color: "#2F426F",
    fontWeight: 700,
  },
  sidebarHeading: {
    fontSize: "20px",
    color: "#2F426F",
    fontWeight: 600,
  },
  text: {
    fontSize: "16px",
    color: "#2F426F",
    fontWeight: 400,
  },
  userDetailsContainer: {
    padding: "1em",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px",
  },
  userDetailsDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    padding: "10px",
  },
  card: {
    borderRadius: "20px",
    backgroundColor: "#fff",
    padding: "1em",
  },
  navLinkText: {
    fontSize: "20px",
    color: "#2F426F",
    fontWeight: 400,
    padding: "10px 0",
    borderBottom: "1px solid #2F426F",
    cursor: "pointer",
  },
  profileStrengthContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export const MobileSidebar: React.FC = () => {
  const [activeLink, setActiveLink] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname; // Get the current path
  const userDetails: any = sessionUtility.getAuthentication().session;


  const classes = useStyles();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (link: string) => {
    setActiveLink(link);
    navigate(link);
    handleClose();
  };

  React.useEffect(() => {
    setActiveLink(path);
  }, [path]);

  const isOperator = userDetails?.role[0]?.authority === "ROLE_OPERATOR:EDIT" ? true :false;
  const isBlogger = userDetails?.role[0]?.authority === "ROLE_BLOGGER:EDIT" ? true :false;
  const isMarketer = userDetails?.role[0]?.authority === "ROLE_MARKETER:EDIT" ? true :false;

  const adminSideBarListItems = [
    {
      title: "User Management",
      icon: userManagementIcon,
      // link: "users-management",
      link: "",
    },
    {
      title: "Fleet Management",
      icon: fleetManagementIcon,
      link: "fleets-management",
    },
    {
      title: "Airport Management",
      icon: airportManagementIcon,
      link: "airports-management",
    },
    {
      title: "Quotation Management",
      icon: quotationManagementIcon,
      link: "quotation-management",
    },
    {
      title: "Booking Management",
      icon: bookingManagementIcon,
      link: "bookings-management",
    },

    // {
    //   title: 'Coupons Management',
    //   icon: couponsManagementIcon,
    //   link: "coupons-management"
    // },

    {
      title: "Manage BMJ Reward Points",
      icon: manageBMJRewardIcon,
      link: "bmj-reward-points",
    },
    {
      title: "Booking Stats",
      icon: bookingStatsIcon,
      link: "booking-stats",
    },
    {
      title: "Offered flight Management",
      icon: offeredFlightManagementIcon,
      link: "offered-flight-management",
    },
    {
      title: "Empty Leg Management",
      icon: emptyLegManagementIcon,
      link: "empty-leg-management",
    },
    {
      title: "Operator Management",
      icon: operatorManagementIcon,
      link: "operator-management",
    },
    {
      title: 'Query Management',
      icon: queryManagementIcon,
      link: "query-management"
    },
    {
      title: "Blogs Management",
      icon: blogsManagementIcon,
      link: "blogs-management",
    },
    {
      title: "Feeds Management",
      icon: feedManagementIcon,
      link: "feeds-management",
    },
    {
      title: "Mails",
      icon: messengerIcon,
      link: "mails",
    },
  ];
  const operatorSideBarListItems = [
    {
      title: "My Aircrafts",
      icon: operatorManagementIcon,
      // link: "operator-my-aircrafts",
      link: "",
    },
  ];
  const bloggerSideBarListItems = [
    {
      title: "Blog Management",
      icon: blogsManagementIcon,
      // link: "operator-my-aircrafts",
      link: "",
    },
  ];
  const marketerSideBarListItems = [
    {
      title: "Mail Management",
      icon: messengerIcon,
      // link: "operator-my-aircrafts",
      link: "",
    },
  ];
  // const sideBarListItems = [
  //   {
  //     title: "User Management",
  //     icon: userManagementIcon,
  //     link: "",
  //   },
  //   {
  //     title: "Fleet Management",
  //     icon: fleetManagementIcon,
  //     link: "fleets-management",
  //   },
  //   {
  //     title: "Airport Management",
  //     icon: airportManagementIcon,
  //     link: "airports-management",
  //   },
  //   {
  //     title: "Quotation Management",
  //     icon: quotationManagementIcon,
  //     link: "quotation-management",
  //   },
  //   {
  //     title: "Booking Management",
  //     icon: bookingManagementIcon,
  //     link: "bookings-management",
  //   },
  //   // {
  //   //   title: "Coupons Management",
  //   //   icon: couponsManagementIcon,
  //   //   link: "coupons-management",
  //   // },
   
  //   {
  //     title: "Manage BMJ Reward Points",
  //     icon: manageBMJRewardIcon,
  //     link: "bmj-reward-points",
  //   },
  //   {
  //     title: "Booking Stats",
  //     icon: bookingStatsIcon,
  //     link: "booking-stats",
  //   },
  //   {
  //     title: "Offered flight Management",
  //     icon: offeredFlightManagementIcon,
  //     link: "offered-flight-management",
  //   },
  //   {
  //     title: "Empty Leg Management",
  //     icon: emptyLegManagementIcon,
  //     link: "empty-leg-management",
  //   },
  //   {
  //       title: "Query Management",
  //       icon: couponsManagementIcon,
  //       link: "query-management",
  //     },
  //   //   {
  //   //     title:'Flights Management',
  //   //     icon: flightsManagementIcon,
  //   //     link:"flights-management"
  //   //   },
  // ];
  const [selectedTab, setSelectedTab] = useState("admin");
  const handleListItem = ({ title, link }: any) => {
    setSelectedTab(title);
    navigate(`/${link}`)
    // window.location.href=link;
  };
  return (
    <>
    {
      (!isOperator && !isBlogger && !isMarketer)
      ?
      <Card
        sx={{
          p: { xs: "0.1em", sm: "0.5em", md: "1.5em" },
          borderRadius: { xs: "10px", sm: "20px" },
        }}
        className="profileUserDetailsCard"
      >
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          fullWidth
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" component="h6">
                {/* <Typography variant="h6" component="h6" className={classes.sidebarHeading}> */}
                {activeLink === "/" && "Users Management"}
                {activeLink === "/fleets-management" && "Fleets Management"}
                {activeLink === "/airports-management" && "Airport Management"}
                {activeLink === "/quotation-management" && "Quotation Management"}
                {activeLink === "/bookings-management" && "Booking Management"}
                {activeLink === "/bmj-reward-points" && "Manage BMJ Reward Points"}
                {activeLink === "/booking-stats" && "Booking Stats"}
                {activeLink === "/offered-flight-management" && "Offered flight Management"}
                {activeLink === "/empty-leg-management" && "Empty Leg Management"}
                {activeLink === "/query-management" && "Query Management"}
                {activeLink === "/operator-management" && "Operator Management"}

               
              </Typography>

              <MenuIcon className={classes.text} />
            </Grid>
          </Grid>
        </Button>
      </Card>
:
<></>
}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {
        isOperator ?
        operatorSideBarListItems?.map((item, index) => {
          return (
            <Grid item xs={12}>
              <MenuItem
                className={`${selectedTab === item.title && "selectedTab"}`}
                sx={{display:"flex",alignItems:"center",gap:"10px"}}
                onClick={() => handleListItem(item)}
              >
                <img
                  src={item.icon}
                  alt={item.title}
                  height="20px"
                  width="20px"
                />{" "}
                 <span>{item.title}</span>
                
              </MenuItem>
            </Grid>
          );
        })
        :
        isBlogger
        ?
        bloggerSideBarListItems?.map((item, index) => {
          return (
            <Grid item xs={12}>
              <MenuItem
                className={`${selectedTab === item.title && "selectedTab"}`}
                sx={{display:"flex",alignItems:"center",gap:"10px"}}
                onClick={() => handleListItem(item)}
              >
                <img
                  src={item.icon}
                  alt={item.title}
                  height="20px"
                  width="20px"
                />{" "}
                 <span>{item.title}</span>
                
              </MenuItem>
            </Grid>
          );
        })
        :
        isMarketer
        ?
        marketerSideBarListItems?.map((item, index) => {
          return (
            <Grid item xs={12}>
              <MenuItem
                className={`${selectedTab === item.title && "selectedTab"}`}
                sx={{display:"flex",alignItems:"center",gap:"10px"}}
                onClick={() => handleListItem(item)}
              >
                <img
                  src={item.icon}
                  alt={item.title}
                  height="20px"
                  width="20px"
                />{" "}
                 <span>{item.title}</span>
                
              </MenuItem>
            </Grid>
          );
        })
        : adminSideBarListItems?.map((item, index) => {
          return (
            <Grid item xs={12}>
              <MenuItem
                className={`${selectedTab === item.title && "selectedTab"}`}
                sx={{display:"flex",alignItems:"center",gap:"10px"}}
                onClick={() => handleListItem(item)}
              >
                <img
                  src={item.icon}
                  alt={item.title}
                  height="20px"
                  width="20px"
                />{" "}
                <span>{item.title}</span>
              </MenuItem>
            </Grid>
          );
        })
        
        }
      </Menu>
    </>
  );
};
