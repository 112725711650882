import { StorageType } from "../enum/storageType";
import config from "../configuration/config.json";
import { IServiceFactory } from "../interface/IServicefactory";
import { IAircraftAvailabilityService, IAircraftService, IAirportService, IBlogService, IBookingService, ICommonService, ICouponService, IFeedService, IFleetService, IFlightService, IItineraryService, IOperatorRequestService, IQuotationService, IQuoteService, IRewardPointsUsageService, IRoleService, IUserService } from "../interface/IService";
import { serviceConstant } from "../constant/serviceConstant";
import { UserMockService } from "../service/mockService/userMockService";
import { UserService } from "../service/realService/userService";
import { IAuthenticationService } from "../interface/IAuthenticationService";
import { AuthenticationMockService } from "../service/mockService/authenticationMockService";
import AuthenticationService from "../service/realService/authenticationService";
import { FleetService } from "../service/realService/fleetService";
import { FleetMockService } from "../service/mockService/fleetMockService";
import { CouponService } from "../service/realService/couponService";
import { AircraftService } from "../service/realService/aircraftService";
import { AirportService } from "../service/realService/airportService";
import { QuotationService } from "../service/realService/quotationService";
import { ItineraryService } from "../service/realService/itineraryService";
import { FlightService } from "../service/realService/flightService";
import { RoleService } from "../service/realService/roleService";
import { AircraftMockService } from "../service/mockService/aircraftMockService";
import { AirportMockService } from "../service/mockService/airportMockService";
import { RoleMockService } from "../service/mockService/roleMockService";
import { CouponMockService } from "../service/mockService/couponMockService";
import { QuotationMockService } from "../service/mockService/quotationMockService";
import { ItineraryMockService } from "../service/mockService/itineraryMockService";
import { FlightMockService } from "../service/mockService/flightMockService";
import { IBooking } from "../interface/IBooking";
import { BookingService } from "../service/realService/bookingService";
import { AircraftAvailabilityService } from "../service/realService/aircraftAvailability";
import { OperatorRequest } from "../service/realService/operatorRequest";
import { StatsMockService } from "../service/mockService/statsMockService";
import { StatsService } from "../service/realService/statsService";
import { CommonService } from "../service/realService/commonService";
import { QuoteService } from "../service/realService/quoteService";
import { RewardPointsUsageService } from "../service/realService/rewardPointsUsage";
import { AircraftSizeCategoryService } from "../service/realService/aircraftSizeCategoryService";
import { FeedService } from "../service/realService/feedService";
import { BlogService } from "../service/realService/blogService";
// import AircraftAvailabiltyCard from "../components/operatorManagement/AircraftAvailabiltyCard";

export class ServiceFactory implements IServiceFactory {
    private storageType = config.storageType;
    constructor(type?: StorageType) {
        if (type !== undefined) {
            this.storageType = type;
        }
    }
    //   getTeamService(): ITeamMemberService {
    //     return new TeamMemberService();
    //   } 
    //   getImpactService() {
    //     throw new Error("Method not implemented.");
    //   }
    getUserService(): IUserService {
        return this.storageType === serviceConstant.storageType
            ? new UserMockService()
            : new UserService();
    }

      getAuthenticationService(): IAuthenticationService {
        return this.storageType === serviceConstant.storageType
          ? new AuthenticationMockService()
          : new AuthenticationService();
      }
      getFleetService(): IFleetService {
        return this.storageType === serviceConstant.storageType
          ? new FleetMockService()
          : new FleetService();
      }
      getAircraftService(): IAircraftService {
        return this.storageType === serviceConstant.storageType
          ? new AircraftService()
          : new AircraftService();
      }
      getAirportService(): IAirportService {
        return this.storageType === serviceConstant.storageType
          ? new AirportService()
          : new AirportService();
      }
      getRoleService(): IRoleService {
        return this.storageType === serviceConstant.storageType
          ? new RoleService()
          : new RoleService();
      }
      //Coupon mock service is needed
      getCouponService(): ICouponService {
        return this.storageType === serviceConstant.storageType
          ? new CouponService()
          : new CouponService();
      }
      getQuotationService(): IQuotationService {
        return this.storageType === serviceConstant.storageType
          ? new QuotationService()
          : new QuotationService();
      }
      getItineraryService(): IItineraryService {
        return this.storageType === serviceConstant.storageType
          ? new ItineraryService()
          : new ItineraryService();
      }
      getFlightService(): IFlightService {
        return this.storageType === serviceConstant.storageType
          ? new FlightService()
          : new FlightService();
      }
      getBookingService(): IBookingService {
        return this.storageType === serviceConstant.storageType
          ? new BookingService()
          : new BookingService();
      }
      getAircraftAvailabilityService(): IAircraftAvailabilityService {
        return this.storageType === serviceConstant.storageType
          ? new AircraftAvailabilityService()
          : new AircraftAvailabilityService();
      }
      getOperatorRequestService(): IOperatorRequestService {
        return this.storageType === serviceConstant.storageType
          ? new OperatorRequest()
          : new OperatorRequest();
      }

  getStatsService(): any {
    return this.storageType === serviceConstant.storageType ? new StatsMockService() : new StatsService();
  }
      
      getCommonService(): ICommonService {
        return this.storageType === serviceConstant.storageType
          ? new CommonService()
          : new CommonService();
      }

      getQuoteService(): IQuoteService {
        return this.storageType === serviceConstant.storageType
          ? new QuoteService()
          : new QuoteService();
      }

      getRewardPointsUsageService(): IRewardPointsUsageService {
        return this.storageType === serviceConstant.storageType
          ? new RewardPointsUsageService()
          : new RewardPointsUsageService();
      }
      getAircraftSizeCategoryService(): any {
        return this.storageType === serviceConstant.storageType
          ? new AircraftSizeCategoryService()
          : new AircraftSizeCategoryService();
      }
      getFeedService(): IFeedService {
        return this.storageType === serviceConstant.storageType
          ? new FeedService()
          : new FeedService();
      }
      getBlogService(): IBlogService {
        return this.storageType === serviceConstant.storageType
            ? new BlogService()
            : new BlogService();
    }

    }
    